/* eslint-disable react/jsx-no-literals */
import { isFieldEmpty } from '@yoweb/prismic-slicemachine/utils/isFieldEmpty';
import { isDefined } from '@yoweb/utils/isDefined';
import type { Slice } from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import { Box } from '@yoweb/ui/components/Box';
import { Text } from '@yoweb/ui/components/typography/Text';
import { captureException } from '@yoweb/utils/sentry';
import { ErrorDisplay } from '@yoweb/ui/components/form';

const isProduction = process.env.NODE_ENV === 'production';

type MissingFields = {
  field: string;
  error?: ReactNode;
}[];

export type PrismicGuardianProps = {
  /**
   * Slice name. Defaults to slice_type if missing
   */
  title?: string;
  /**
   * Slice response data
   */
  slice: Slice;
  /**
   * required slice.primary fields in an array
   */
  primaryRequired?: readonly string[];
  /**
   * required slice.items fields in an array
   */
  itemsRequired?: readonly string[];
  children: React.ReactNode;
};

/**
 * Prismic does not have a way to ensure if a field is required or not.
 * This component guards against missing required fields in Prismic responses.
 * If a "required" field is missing, it will render a specific warning message.
 * That way the content editors can easily see if they are missing any fields.
 */
export const PrismicGuardian = ({
  title,
  slice,
  primaryRequired,
  itemsRequired,
  children,
}: PrismicGuardianProps) => {
  if (!isDefined(primaryRequired) && !isDefined(itemsRequired)) {
    throw new Error('PrismicGuardian requires either primaryRequired or itemsRequired props');
  }

  const sliceName = title ?? slice.slice_type.toUpperCase();

  // Validate primary fields
  const missingPrimaryFields = primaryRequired?.map((field) => ({
    field,
    error: isFieldEmpty(slice.primary?.[field as keyof Slice['primary']])
      ? `Missing required field: ${field}`
      : undefined,
  }));

  // Validate items fields
  const missingItemsFieldsArr = slice.items
    ?.map((item, itemIndex) =>
      itemsRequired?.map((field) => ({
        field,
        error: isFieldEmpty(item?.[field as keyof Slice['items'][0]])
          ? `Item ${itemIndex + 1} is missing required field: ${field}`
          : undefined,
      })),
    )
    .filter(Boolean);

  const hasMissingPrimaryFields = missingPrimaryFields?.some(({ error }) => error);
  const hasMissingItemsFields = missingItemsFieldsArr?.some((missingFields) =>
    missingFields?.some(({ error }) => error),
  );

  /**
   * Renders a warning message if a required field is missing
   * @param type - 'primary' or 'items'
   * @param missingFields - Array of missing fields
   */
  const renderMissingFields = (missingFields: MissingFields) =>
    missingFields.map(
      ({ field, error }) =>
        isDefined(error) && (
          <Text key={field} size="sm">
            {error}
          </Text>
        ),
    );

  const getMissingFieldsMessage = (missingFields: MissingFields) =>
    missingFields
      .map(({ error }) => error)
      .filter(Boolean)
      .map((error) => `- ${error}`)
      .join('\n');

  const sliceKindDescription = `"${sliceName}" with variation "${slice.variation}"`;

  if (hasMissingPrimaryFields || hasMissingItemsFields) {
    const displayPrimaryMissingFields = hasMissingPrimaryFields && isDefined(missingPrimaryFields);
    const displayItemsMissingFields =
      hasMissingItemsFields && isDefined(missingItemsFieldsArr.length > 0);

    if (isProduction) {
      let errorMessage = '';

      if (displayPrimaryMissingFields) {
        errorMessage += `${sliceKindDescription} is missing fields from non-repeatable zone:\n${getMissingFieldsMessage(
          missingPrimaryFields,
        )}.\n\n`;
      }

      if (displayItemsMissingFields) {
        errorMessage += `${sliceKindDescription} is missing fields from repeatable zone:\n${missingItemsFieldsArr
          .map(
            (missingItemsFields) =>
              missingItemsFields && getMissingFieldsMessage(missingItemsFields),
          )
          .join('\n\n')}.`;
      }

      // Log error to Sentry
      captureException(new Error(errorMessage ?? `${sliceName} is missing some fields.`));

      return null;
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={{ _: 'normal1', md: 'large1' }}
        py="large3"
      >
        <ErrorDisplay title={`Prismic CMS error occurred.`} withBorder>
          <Text size="sm" variant="muted">
            {`Some fields are missing from slice ${sliceKindDescription}.`}
            <br />
            {'Please ensure that those fields are filled in correctly.'}
          </Text>

          {displayPrimaryMissingFields && (
            <Box mt="normal1">
              <Text as="h3" weight="bold" size="sm">
                {'Non-repeatable zone'}
              </Text>
              <Text as="div" size="sm">
                {renderMissingFields(missingPrimaryFields)}
              </Text>
            </Box>
          )}

          {displayItemsMissingFields && (
            <Box mt="normal1">
              <Text as="h3" weight="bold" size="sm">
                {'Repeatable zone'}
              </Text>
              <Text as="div" size="sm">
                {missingItemsFieldsArr.map(
                  (missingItemsFields) =>
                    missingItemsFields && renderMissingFields(missingItemsFields),
                )}
              </Text>
            </Box>
          )}
        </ErrorDisplay>
      </Box>
    );
  }

  return <>{children}</>;
};
