import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { type SeoSliceResponse, requiredPrimaryFields, requiredItemsFields } from './SeoSliceTypes';
import { SocialSEO } from '@yoweb/ui/components/SocialSEO';

type SeoSlicerProps = {
  slice: SeoSliceResponse;
};

const SeoSlicer = ({ slice }: SeoSlicerProps) => {
  const { title, description, image, siteName, url, type } = slice.primary;

  const props = {
    title,
    description,
    image: image?.url,
    imageAlt: image?.alt ?? undefined,
    siteName,
    url,
    type,
  };

  return <SocialSEO {...props} />;
};

export default withPrismicGuardian(SeoSlicer, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
