import type { ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const SeoSliceType = 'seo_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  title: string | undefined;
  description: string | undefined;
  image?: ImageField | undefined;
  siteName?: string | undefined;
  url?: string | undefined;
  type?: string | undefined;
};

export type SeoSliceResponse = PrismicSlice<
  typeof SeoSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
