import Head from 'next/head';

export type SocialSEOProps = {
  title?: string | null;
  image?: string | null;
  url?: string | null;
  description?: string | null;
  type?: string | null;
  imageAlt?: string | null;
  siteName?: string | null;
};

export const SocialSEO = ({
  title,
  description,
  image,
  imageAlt,
  url,
  siteName,
  type,
}: SocialSEOProps) => (
  <Head>
    <title key="page-title">{title || 'Yohana'}</title>
    <meta property="og:title" content={title || 'Yohana'} />
    {type && <meta property="og:type" content={type} />}
    {image && <meta property="og:image" content={image} key="og:image" />}
    {url && <meta property="og:url" content={url} />}
    {image && <meta name="twitter:card" content="summary_large_image" />}
    <meta property="og:site_name" content={siteName || 'Yohana'} />
    {description && (
      <>
        <meta name="description" content={description} key="page-description" />
        <meta property="og:description" content={description} />
      </>
    )}
    {imageAlt && <meta name="twitter:image:alt" content={imageAlt} />}
  </Head>
);
