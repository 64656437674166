import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';

export const ErrorIcon = (props: IconProps) => (
  <IconBase {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 12A9.75 9.75 0 0 0 12 21.75 9.75 9.75 0 0 0 21.75 12 9.75 9.75 0 0 0 12 2.25 9.75 9.75 0 0 0 2.25 12ZM12 6.25a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75Zm-1 10a1.001 1.001 0 1 1 1 1 1 1 0 0 1-1-1Z"
      fill="currentColor"
    />
  </IconBase>
);

export default ErrorIcon;
