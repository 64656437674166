/**
 * Omits is used to return a copy of the object that composed of the own and
 * inherited enumerable property paths of the given object that are not omitted.
 * @example
 * const obj = {
 *   name: 'Name',
 *   password: 'pass',
 *   username: 'username',
 * };
 *
 * omit(obj, ['password', 'username']) // { name: "username" }
 *
 */
export const omit = <T extends object, U extends keyof T>(obj: T, keys: U[]): Omit<T, U> =>
  (Object.keys(obj) as U[]).reduce(
    (acc, curr) => (keys.includes(curr) ? acc : { ...acc, [curr]: obj[curr] }),
    {} as Omit<T, U>,
  );
