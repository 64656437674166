import type { ComponentType } from 'react';

import {
  PrismicGuardian,
  type PrismicGuardianProps,
} from '@yoweb/prismic-slicemachine/components/PrismicGuardian';
import type { Slice } from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import { omit } from '@yoweb/utils/omit';

const DEFAULT_TYPE = 'default';
const VARIATIONS_TYPE = 'variations';

// Helps coverting union types to Mapped union types.
type Distribute<U> = U extends unknown ? U : never;

// PrismicGuardian component props that are used current HOC(withPrismicGuardian).
type GuardianFields = Pick<PrismicGuardianProps, 'primaryRequired' | 'itemsRequired'>;

// Incoming guardian props that are used when initiating withPrismicGuardian HOC. ()  current HOC(withPrismicGuardian).
type GuardianProps =
  | ({
      type?: typeof DEFAULT_TYPE;
    } & GuardianFields)
  | {
      type: typeof VARIATIONS_TYPE;
      variations: {
        [variationId in Distribute<Slice['variation']>]?: GuardianFields;
      };
    };

export const withPrismicGuardian = <TProps extends Record<string, unknown> & { slice: Slice }>(
  WrappedComponent: ComponentType<TProps>,
  guardianProps: GuardianProps,
) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  if (!guardianProps.type) {
    guardianProps.type = DEFAULT_TYPE;
  }

  const ComponentWithGuardian = (props: TProps) => {
    const { slice } = props;

    if (guardianProps.type === VARIATIONS_TYPE && guardianProps.variations[slice.variation]) {
      return (
        <PrismicGuardian {...guardianProps.variations[slice.variation]} slice={slice}>
          <WrappedComponent {...props} />
        </PrismicGuardian>
      );
    }

    if (guardianProps.type === DEFAULT_TYPE) {
      return (
        <PrismicGuardian {...omit(guardianProps, ['type'])} slice={slice}>
          <WrappedComponent {...props} />
        </PrismicGuardian>
      );
    }

    return null;
  };

  ComponentWithGuardian.displayName = `withPrismicGuardian(${displayName})`;

  return ComponentWithGuardian;
};
