/* eslint-disable react/no-array-index-key */
import { Fragment, useMemo } from 'react';

import type {
  Slice,
  SliceTemplateProps,
  TemplateProps,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';
import type { DocumentMetaType, PrismicPageSlicesProps } from '../customtypes/pageTypes';
import SeoSlice from '../slices/SeoSlice';
import type { SeoSliceResponse } from '../slices/SeoSlice/SeoSliceTypes';

const DEFAULT_TYPE = 'default';

type Injectable = {
  type: 'injectable';
  component: ReactNode;
};

export type PrismicSlicesProps = PrismicPageSlicesProps & {
  /**
   * Prismic template component to render.
   */
  template: React.ComponentType<
    SliceTemplateProps & {
      templateProps?: TemplateProps;
    }
  >;

  /**
   * Used for additional props to pass the template
   */
  templateProps?: TemplateProps;
  /**
   * Injectable components to render. This components will be injected into the template.
   */
  injectables?: {
    [key: number]: React.ReactNode;
  };

  children?: React.ReactNode;

  clickHandlers?: { [index: string]: () => void };
};

const hasSEOData = (_meta: DocumentMetaType | undefined) =>
  _meta &&
  (_meta.title ||
    _meta.description ||
    _meta.siteName ||
    _meta.url ||
    _meta.image?.url ||
    _meta.type);

export const Slices = ({
  slices,
  meta,
  template: TemplateComp,
  templateProps,
  injectables,
  children,
  clickHandlers,
}: PrismicSlicesProps): JSX.Element => {
  const slicesAndInjectables = useMemo(() => {
    const newSlices: (Slice | Injectable)[] = [...slices];

    for (const key in injectables) {
      if (Object.prototype.hasOwnProperty.call(injectables, key)) {
        const position = parseInt(key, 10);

        if (position <= newSlices.length) {
          newSlices.splice(position, 0, {
            type: 'injectable',
            component: injectables[position],
          });
        }
      }
    }

    return newSlices;
  }, [slices, injectables]);

  return (
    <>
      {hasSEOData(meta) && (
        <SeoSlice
          slice={
            { primary: meta as unknown, slice_type: DEFAULT_TYPE as string } as SeoSliceResponse
          }
        />
      )}
      {slicesAndInjectables.map((slice, index) => {
        const injectable = slice as Injectable;

        if (injectable?.type === 'injectable') {
          return <Fragment key={`${injectable.type}-${index}`}>{injectable?.component}</Fragment>;
        }

        return (
          <TemplateComp
            slice={slice as Slice}
            slices={slices}
            sliceIndex={index}
            clickHandlers={clickHandlers}
            key={`${(slice as Slice).slice_type}-${index}`}
            templateProps={templateProps}
          >
            {children}
          </TemplateComp>
        );
      })}
    </>
  );
};
