import { type ReactNode, useEffect, useRef, useState } from 'react';
import styled, { type Colors, css } from 'styled-components';

import { Grid } from '@yoweb/ui/components/Grid';
import { Text, Interactive } from '@yoweb/ui/components/typography';
import { Link } from '@yoweb/ui/components/Link';
import { ErrorIcon } from '@yoweb/ui/components/Icon/ErrorIcon';
import { captureException } from '@yoweb/utils/sentry';
import { pxToRem } from '@yoweb/ui/styles/utils';
import { useTranslation } from '@yoweb/next-i18next';
import { getColor, getRadii, getSpace } from '@yoweb/ui/styles/utils/theme';
import { isInViewport } from '@yoweb/utils/isInViewport';
import type { ColorType } from '@yoweb/ui/components/typography/textBase';

export type ErrorObject = {
  title?: string;
  message?: ReactNode;
};

export const ErrorMessageLink = ({ href, children }: { href: string; children?: ReactNode }) => (
  <Link href={href}>
    <Interactive size="sm" textDecoration="underline" variant="fg" weight="regular">
      {children}
    </Interactive>
  </Link>
);

export type ErrorMessage = ErrorObject & {
  /** display an error to the user */
  set(error?: ErrorObject | Error | string, description?: ErrorObject): void;
  /** clear the error display */
  clear(): void;
  error?: ErrorObject;
};

export type ErrorProps = DataTestId & {
  /** An optional title to error message */
  title?: string;

  /** the message (string, number, react element) to display in the error area */
  message?: ReactNode;

  /** @deprecated use useError instead */
  children?: ReactNode;

  /** whether to display a red border on the side of the error container */
  withBorder?: boolean;

  backgroundColor?: keyof Colors;

  /** allows overriding the variant for the error's text. Defaults to 'muted */
  textVariant?: ColorType;

  /** If true then scrolls to element */
  scrollTo?: boolean;

  /**
   * Parent element of the error message - used for scrolling.
   * Useful for cases when the error message is displayed in a modal.
   */
  scrollParentElement?: HTMLElement;

  /** Optional custom icon instead of error one */
  icon?: ReactNode;
};

/**
 * A component used to portray form errors.
 */
function ErrorDisplay({
  children,
  title,
  message,
  withBorder,
  backgroundColor,
  textVariant = 'muted',
  scrollTo,
  scrollParentElement,
  icon,
  ...props
}: ErrorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const displayMessage = children || message;

  useEffect(() => {
    if (!ref?.current) return;
    if (!scrollTo) return;

    if (!isInViewport(ref.current)) {
      (scrollParentElement ?? window).scrollTo({ top: ref.current.offsetTop, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!displayMessage) {
    return null;
  }

  return (
    <ErrorContainer withBorder={withBorder} backgroundColor={backgroundColor} {...props} ref={ref}>
      <IconWrapper>{icon ?? <ErrorIcon size={20} variant="danger" />}</IconWrapper>
      <Grid gap="small1">
        {title && (
          <Text weight="bold" size="md">
            {title}
          </Text>
        )}
        <Text as={children ? 'div' : 'p'} variant={textVariant} size="sm" whiteSpace={'pre-wrap'}>
          {displayMessage}
        </Text>
      </Grid>
    </ErrorContainer>
  );
}

type UseErrorProp = ErrorObject | Error | string;

const isUseErrorObject = (error?: UseErrorProp): error is ErrorObject => {
  if (!error || typeof error === 'string' || error instanceof Error) {
    return false;
  }

  return 'title' in error && 'message' in error;
};

export function useError(): ErrorMessage {
  const { t } = useTranslation<'common'>();
  const [useErrorObject, setError] = useState<ErrorObject | undefined>();

  return {
    set(error?: UseErrorProp) {
      if (isUseErrorObject(error)) {
        setError(error);
        return;
      }

      const message = typeof error === 'string' ? error : t('error.description');

      setError({ message });

      if (error instanceof Error) {
        captureException(error);
      }
    },
    clear() {
      setError(undefined);
    },
    title: useErrorObject?.title,
    message: useErrorObject?.message,
    error: useErrorObject,
  };
}

const IconWrapper = styled.div`
  align-self: baseline;
  color: ${getColor('critical')};
  line-height: 1;
`;

const ErrorContainer = styled.div<{ withBorder?: boolean; backgroundColor?: keyof Colors }>`
  align-items: center;
  display: grid;
  grid-gap: ${pxToRem(12)};
  grid-template-columns: auto 1fr;
  position: relative;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${getColor(backgroundColor)};
    `}

  ${({ withBorder }) =>
    withBorder &&
    css`
      border: 1px solid ${getColor('base100')};
      border-radius: ${getRadii('medium')};
      padding: ${getSpace('normal1')};
    `}
`;

export default ErrorDisplay;
