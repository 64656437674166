import type { FilledLinkToWebField } from '@prismicio/types';

import { isDefined } from '@yoweb/utils/isDefined';
import { isObjectEmpty } from '@yoweb/utils/isObjectEmpty';
import { isObjectLike } from '@yoweb/utils/isObjectLike';

/**
 * Validates if Prismic field is empty(missing)
 * @param field - Any field from slice.primary or slice.items
 */
export const isFieldEmpty = (field: unknown): boolean => {
  // null and undefined are considered missing field
  if (!isDefined(field)) return true;

  // Empty array or array with one empty object is considered missing field

  if (
    Array.isArray(field) &&
    (field.length === 0 || (isObjectEmpty(field?.[0]) && isObjectLike(field?.[0])))
  ) {
    return true;
  }

  // Empty object is considered missing field
  if (isObjectEmpty(field) && isObjectLike(field)) {
    return true;
  }

  // If field is a link, it is considered missing field if it is missing link.url
  if (isObjectLike(field)) {
    const isLinkType = (field as Record<string, unknown>)['link_type'];

    if (isLinkType && !isDefined((field as FilledLinkToWebField)['url'])) {
      return true;
    }
  }

  return false;
};
