import styled, { css } from 'styled-components';
import type { FontSizes, LineHeights } from 'styled-components';

import { th } from '@yoweb/ui/styles/utils/theme';
import { textBase } from './textBase';
import { createSizeMixin, type WithSize } from '@yoweb/ui/styles/utils/mixins';
import type { TextBaseProps } from './textBase';

type LabelSize = 'xs' | 'sm' | 'md';
type LabelProps = TextBaseProps & WithSize<LabelSize>;

/** Map label size to theme key. */
const sizeMapping: Record<LabelSize, keyof FontSizes> = {
  xs: 'labelXs',
  sm: 'labelSm',
  md: 'labelMd',
};

/** Map label lineHeights to theme key. */
const lineHeightMapping: Record<LabelSize, keyof LineHeights> = {
  xs: 'labelXs',
  sm: 'labelSm',
  md: 'labelMd',
};

/**
 * font-size + line-height + letter-spacing mixin.
 * Control all with a single prop.
 */
const labelSizeMixin = createSizeMixin<LabelSize, LabelProps>(
  ({ size = 'sm' as LabelSize }, tagFn) =>
    tagFn`
    font-size: ${th.getFontSize(sizeMapping[size])};
    line-height: ${th.getLineHeight(lineHeightMapping[size])};
    letter-spacing: ${th.getLetterSpacing(sizeMapping[size])};
  `,
);

/**
 * Label component with multiple props to modify.
 */
const labelStyles = css<LabelProps>`
  font-weight: ${th.getFontWeight('bold')};
  ${textBase};
  ${labelSizeMixin};
`;

export const Label = styled.p<LabelProps>`
  ${labelStyles}
`;

Label.defaultProps = {
  $textTransform: 'uppercase',
};

export { labelSizeMixin, labelStyles };
export type { LabelProps };
